import { FC } from 'react';
import * as React from 'react';

import Layout from '../layouts';

const NotFoundPage: FC = () => (
  <Layout url="404" title="Not found" disableIndex>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
